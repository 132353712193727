import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import FreeTrial from "../components/free-trial";
import {LaganEngineeringTestimonial} from "../components/testimonial";
import SEO from "../components/seo";

import LeftFeature from '../components/left-feature';
import RightFeature from '../components/right-feature';
import FeaturePararaph from '../components/feature-paragraph'

import FreeTrialButton from '../components/free-trial-button';

import dataProcessingSrc from '../images/data-processing.svg';
import supportSrc from '../images/support.svg';
import multiDeviceSrc from '../images/multi-device.png';
import supportSmallSrc from '../images/support-small.svg';
import switchToTkSrc from '../images/switch-to-tk.svg';


const SageTimesheetsAlternative = () => (
  <Layout>
    <SEO 
    	title="Best Sage Timesheets Alternative"
    	description="Sage Timesheets is shutting down and we've got you covered."
    	 />
    <Navbar />
    <div className="bg-gray-50">
		  <div className="relative bg-white overflow-hidden">
			  <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
			    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
			      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
			        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
			          <h2 className="mt-1 text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-5xl">
			            Looking for a <br /> <span style={{ color: '#00D03E' }}>Sage Timesheets </span> <br /> alternative?
			          </h2>

			          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
			            Need to move off Sage Timesheets? TimeKeeper has got you covered!
			          </p>

			          <FreeTrialButton />
			        </div>
			        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
			          <div className="relative mx-auto w-full rounded-lg lg:max-w-md">
			            <button className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
			              <img className="w-full" src={switchToTkSrc} alt="Sage Online Timesheets vs TimeKeeper" />
			            </button>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<div className="py-8 bg-gray-50 overflow-hidden lg:py-24">
			  	<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
				     <RightFeature 
				    	sectionTitle="Modern Time and Attendance Software" 
				    	title="Cloud-based Simple Time and Attendance Software" 
				    	body={
				    		<div>
						        <FeaturePararaph>
						          Employees can clock in with their mobiles, via a shared kiosk, the web or even submit their timesheets manually via the app.
						        </FeaturePararaph>
						        <FeaturePararaph>
						        	All that information gets sent up to TimeKeeper and we'll take care of calculating the right amounts of regular and overtime worked per employee.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={multiDeviceSrc}
				    	imgAlt="SageTimesheets Warning"
				    />
				    <LeftFeature 
				    	sectionTitle="UK Based Support" 
				    	title="Friendly Human UK-based Support"
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          For time and attendance, you can rest easy knowing you can reach out to a team who can help you through any issues when you encounter them.
						        </FeaturePararaph>
						        <FeaturePararaph>
						           With TimeKeeper you can chat directly (online or via the phone) to a UK based team who would be more than happy to help you on your move.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={supportSrc}
				    	imgAlt="UK Based Support"
				     />
				     <RightFeature 
				    	sectionTitle="Integrates with Sage50 Payroll" 
				    	title="Send Timesheets to Sage50 Payroll" 
				    	body={
				    		<div>
						        <FeaturePararaph>
						          Want to move off Sage Timesheets but keep Sage50 Cloud Payroll?
						        </FeaturePararaph>
						        <FeaturePararaph>
						        	We have a dedicated Sage50 Payroll integration, and we'll even do a 1 hour phone call to walk through any questions you might have.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={dataProcessingSrc}
				    	imgAlt="TimeKeeper has a direct integration with Sage 50 Payroll"
				    />
				    <LeftFeature 
				    	sectionTitle="Supporting Small Business" 
				    	title="We're a small business just like you"
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          Sage are huge company who provide great payroll and accounting software.
						        </FeaturePararaph>
						        <FeaturePararaph>
						           TimeKeeper is a small UK based company focused solely on Time and Attendance. Every £ we earn goes towards making a better product for our users as well as helping us look after our loved ones.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={supportSmallSrc}
				    	imgAlt="Supporting small business"
				     />
				</div>
				<LaganEngineeringTestimonial />
				<FreeTrial />
			</div>
		</div>
		<Footer/>
  </Layout>
)

export default SageTimesheetsAlternative;